import {
    DfaStatusEnum,
    ICouponPaymentCalendarTable,
    IDfaFront,
    IDfaOwner,
    PriceSource,
    ProfileType,
    TCouponPaymentDate,
    dfaBackToFront,
} from '@dltru/dfa-models'
import { getEmitterData, getProfileRequestType } from '@dltru/dfa-ui'

import { getExternalRateHelper } from '@store/helper'

import api from '@services/api'

export async function getDfaByIdHelper(dfaId: number): Promise<IDfaFront> {
    try {
        const { data, error: errorDfa } = await api.lib.getDfaById(dfaId)

        if (errorDfa || !data?.item) {
            throw errorDfa
        }

        const { error: errorEmitterType, data: dataEmitterTypes } =
            await api.lib.getProfileTypeByUserService(data.item.emitter_id)

        if (errorEmitterType) {
            throw new Error(errorEmitterType)
        }

        const emitterProfileTypes = (dataEmitterTypes?.items as { type: ProfileType }[]).map(
            (profileType) => profileType.type,
        )

        const publicInfoPayload = getProfileRequestType(emitterProfileTypes)

        const { data: dataEmitterProfile } = await api.lib.getPublicInformation(
            publicInfoPayload,
            data.item.emitter_id,
        )

        let availableItem = {}
        const { data: availableData } = await api.lib.getDfaAvailableDeals(data.item.id)
        availableItem = availableData?.item ?? {}

        let restPriceItem = {}

        if (data.item.price_source_type === PriceSource.dynamic && data.item.price_source) {
            const { item } = await getExternalRateHelper(data.item.price_source)
            data.item.price_external = item.rate / 100

            if (data.item.status === DfaStatusEnum.collection_started) {
                const { data: restPrice } = await api.lib.getDfaAvailableDealsV2(data.item.id)
                restPriceItem = restPrice?.item ?? {}
            }
        }

        const emitterInfo = getEmitterData(emitterProfileTypes, dataEmitterProfile?.item)
        const dfa = dfaBackToFront(data.item, emitterInfo, {
            ...availableItem,
            ...restPriceItem,
        })

        return dfa
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
    }
}

export interface IGetDfaBalanceByIdHelperProps {
    uuid: string | undefined
    dfaId: number
    withPayoffAgreements?: boolean
    isInvestorRepaymentCheck: boolean
}

export async function getDfaBalanceByIdHelper(
    payload: IGetDfaBalanceByIdHelperProps,
): Promise<IDfaOwner | null> {
    try {
        const { data, error } = await api.lib.getDfaOwners(payload.dfaId)

        if (error) {
            throw new Error(error)
        }

        if (data?.items?.length) {
            const item = (data.items as IDfaOwner[]).find((item) => item.user_id === payload.uuid)

            if (item) {
                const balance = item.balances[0] ?? {}
                if (payload.withPayoffAgreements && payload.isInvestorRepaymentCheck) {
                    const { data: dataPayoffAgreements } = await api.lib.getPayoffAgreements({
                        asset_id: payload.dfaId,
                        user_uuid: payload.uuid,
                        limit: 1,
                    })

                    balance.is_investor_repayment_confirmed = Boolean(
                        dataPayoffAgreements?.item?.items?.length,
                    )
                }

                return balance as IDfaOwner // костыль, надо разобраться с типизацией от api
            } else {
                return {} as IDfaOwner
            }
        }

        return null
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
    }
}

type TPaymentDateTableData = { data: ICouponPaymentCalendarTable[]; total: number }
const PER_PAGE = 10

export const normalyzeCouponePayment = (
    data?: TCouponPaymentDate[],
    gap?: number,
): TPaymentDateTableData => {
    if (!data) {
        return { data: [], total: 0 }
    }

    let counter = 1

    const normalyzed = data.reduce((acc, curr) => {
        const rows =
            curr?.coupon_payment_facts?.map((value, index, array) => {
                let rowSpan = array.length - index > PER_PAGE ? PER_PAGE : array.length - index

                if (counter > PER_PAGE) {
                    counter = 2
                    return {
                        uuid: `${curr.id}${index}`,
                        id: curr.id,
                        asset_id: curr.asset_id,
                        payment_date: curr.payment_date,
                        notification_date: curr.notification_date,
                        cpf_id: value.id,
                        cpf_payment_date: value.payment_date,
                        cpf_price_per_dfa: value.price_per_dfa,
                        cpf_amount_dfa: value.amount_dfa,
                        cpf_total_price: value.total_price,
                        rowSpan,
                    }
                }

                if (index > 0) {
                    rowSpan = 0
                } else {
                    const limit = PER_PAGE + 1 - counter
                    if (rowSpan > limit) {
                        rowSpan = limit
                    }
                }

                counter = counter + 1

                return {
                    uuid: `${curr.id}${index}`,
                    id: curr.id,
                    asset_id: curr.asset_id,
                    payment_date: curr.payment_date,
                    notification_date: curr.notification_date,
                    cpf_id: value.id,
                    cpf_payment_date: value.payment_date,
                    cpf_price_per_dfa: value.price_per_dfa,
                    cpf_amount_dfa: value.amount_dfa,
                    cpf_total_price: value.total_price,
                    rowSpan,
                }
            }) || []

        if (!rows.length) {
            if (counter > PER_PAGE) {
                counter = 2
                const row = {
                    uuid: `${curr.id}-100`,
                    id: curr.id,
                    asset_id: curr.asset_id,
                    payment_date: curr.payment_date,
                    notification_date: curr.notification_date,
                    rowSpan: 1,
                }
                acc = [...acc, row]
                return acc
            }

            counter = counter + 1
            const row = {
                uuid: `${curr.id}-100`,
                id: curr.id,
                asset_id: curr.asset_id,
                payment_date: curr.payment_date,
                notification_date: curr.notification_date,
                rowSpan: 1,
            }
            acc = [...acc, row]
            return acc
        }

        acc = [...acc, ...rows]
        return acc
    }, [] as unknown as ICouponPaymentCalendarTable[])

    const start = gap ? (gap - 1) * PER_PAGE : 0
    const end = gap ? gap * PER_PAGE : 0

    return { data: normalyzed.slice(start, end), total: normalyzed.length }
}
