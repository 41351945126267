import dayjs from 'dayjs'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import {
    DecisionMakingType,
    IDfaFront,
    ProfileRequestType,
    ProfileType,
    dfaBackToFront,
    dfaFrontToBack,
} from '@dltru/dfa-models'
import {
    ErrorCodes,
    VIEW_DATE_FORMAT,
    closeMessage,
    errorTranslates,
    getEmitterData,
    getProfileRequestType,
    openMessage,
    openNotification,
} from '@dltru/dfa-ui'

import { isAxiosError } from '@utils/typeGuard'

import { authSelector } from '@store/auth/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import {
    getCalculateFeeHelper,
    signCustomTransaction,
    signData,
    signDataEmission,
} from '@store/helper'
import { loadsetAccountMoneyByUser } from '@store/money'
import { paymentsSchedulerSlice } from '@store/paymentsScheduler'
import { profileSelector } from '@store/profiles/details/selectors'
import {
    clearDataTransactionDetails,
    setIsLoadingTransactionDetails,
    setItemsTransactionDetails,
} from '@store/sign'
import { transactionDetailsSelectors } from '@store/sign/selectors'
import IAppState from '@store/states'

import api from '@services/api'

import { IGetDfaBalanceByIdHelperProps, getDfaBalanceByIdHelper, getDfaByIdHelper } from './helpers'
import {
    dfaDetailsSlice,
    downloadDecisionTemplate,
    dropDfaDetails,
    emissionDevDfa,
    emissionDevDfaPrefly,
    getDfaBalanceById,
    getDfaById,
    getRepaymentDfaFee,
    makeIssueFailed,
    patchRedeemDate,
    postDfa,
    putDfa,
    repaymentDfaConfirmByInvestor,
    repaymentDfaConfirmByInvestorPrefly,
    revokeEmitment,
    sendToTellerDfa,
    setCouponPayment,
    startRepayment,
    startRepaymentPrefly,
    storeLink,
    updateDfaBalance,
    updateDfaDetails,
    updateLinks,
} from './index'

function* handlePostDfa({ payload }: ReturnType<typeof postDfa>) {
    if (!payload) {
        return
    }
    try {
        yield* put(updateDfaDetails({ error: '', isLoading: true }))
        const uuid = yield* select(authSelector.selectUserUid)
        const { account } = yield* select((store: IAppState) => store.moneyAccounts)

        const mappedDfa = dfaFrontToBack({ ...payload, emitter_id: uuid } as IDfaFront)

        const { data: dataDfa, error } = yield* call(api.lib.storeDfa, {
            ...mappedDfa,
            emitter_account_id: account?.id,
        })

        if (error) {
            throw error
        }

        const { error: errorEmitterType, data: dataEmitterTypes } = yield* call(
            api.lib.getProfileTypeByUserService,
            dataDfa.item.emitter_id,
        )

        if (errorEmitterType) {
            throw new Error(errorEmitterType)
        }
        const emitterProfileTypes = (dataEmitterTypes.items as { type: ProfileType }[]).map(
            (profileType) => profileType.type,
        )
        const { error: errorEmitterProfile, data: dataEmitterProfile } = yield* call(
            api.lib.getPublicInformation,
            getProfileRequestType(emitterProfileTypes) as
                | ProfileRequestType.LEGAL_ENTITY
                | ProfileRequestType.BUSINESSMAN,
            dataDfa.item.emitter_id,
        )
        if (errorEmitterProfile) {
            throw new Error(errorEmitterProfile)
        }

        const emitterInfo = getEmitterData(emitterProfileTypes, dataEmitterProfile?.item)
        const dfa = dfaBackToFront(dataDfa.item, emitterInfo)
        if (payload.decision_making_type === DecisionMakingType.out_of_platform) {
            openNotification({
                type: 'success',
                message: 'Заявление направлено на проверку оператору ИС',
                description:
                    'Оператор проверит решение в течение 5 рабочих дней. После проверки и корректировки заявка будет направлена вам для подтверждения условий.',
            })
        }

        yield* put(updateDfaDetails({ error: '', isLoading: false, ...dfa }))
        yield* put(paymentsSchedulerSlice.actions.setDates(dfa.coupon_payment_calendar || []))
        yield* put(loadsetAccountMoneyByUser())
    } catch (error) {
        const description = errorTranslates[error?.response?.data?.error?.code as ErrorCodes]
        openMessage({
            type: 'error',
            message: `Не удалось отправить Решение о выпуске${
                description ? `: ${description}` : ''
            }`,
        })
        yield* put(updateDfaDetails({ error: 'dfa error', isLoading: false }))
    }
}

function* handlePutDfa({ payload }: ReturnType<typeof putDfa>) {
    try {
        yield* put(updateDfaDetails({ error: '', isLoading: true }))

        const { error, data } = yield* call(
            api.lib.updateDfaService,
            payload.id as number,
            dfaFrontToBack(payload),
        )
        if (error || data?.error) {
            throw error || data?.error
        }
        yield* put(updateDfaDetails({ error: '', isLoading: false, ...payload }))
        yield* put(paymentsSchedulerSlice.actions.setDates(payload.coupon_payment_calendar || []))
        yield* put(loadsetAccountMoneyByUser())
    } catch (error) {
        openMessage({ type: 'error', message: 'Возникли ошибки при отправке запроса' })
        yield* put(updateDfaDetails({ error: 'update dfa error', isLoading: false }))
    }
}

function* handleDfaById({ payload }: ReturnType<typeof getDfaById>) {
    try {
        yield* put(dropDfaDetails())
        yield* put(updateDfaDetails({ error: '', isLoading: true }))

        const dfa = yield* call(getDfaByIdHelper, payload.dfaId)

        yield* put(updateDfaDetails({ error: '', isLoading: false, ...dfa }))

        if (payload.withBalance) {
            yield* put(getDfaBalanceById({ dfaId: payload.dfaId, withPayoffAgreements: true }))
        }
    } catch (error) {
        yield* put(updateDfaDetails({ error: 'dfa error', isLoading: false }))
    }
}
function* handleStartRepaymentPrefly({ payload }: ReturnType<typeof startRepayment>) {
    try {
        openMessage({
            type: 'loading',
            key: 'prefly',
            message: 'Формирование транзакции',
        })

        yield* put(setIsLoadingTransactionDetails(true))
        const { error, data } = yield* call(api.lib.postDfaRepaymentPayload, {
            asset_id: payload.asset_id,
            skid: payload.skid,
        })

        if (error || data?.error) {
            throw error || data?.error
        }
        yield* put(setItemsTransactionDetails(data))
        closeMessage('prefly')
        yield* put(setIsLoadingTransactionDetails(false))
    } catch (error) {
        yield* put(setIsLoadingTransactionDetails(false))
        closeMessage('prefly')
        openMessage({
            type: 'error',
            message: 'Ошибка формирования транзакции',
        })
    }
}

function* handleStartRepayment({ payload }: ReturnType<typeof startRepayment>) {
    try {
        yield* put(dfaDetailsSlice.actions.setIsLoading(true))

        const data = yield* select(transactionDetailsSelectors.selectItems)

        if (data?.hash) {
            const dataForSign = {
                asset_id: payload.asset_id,
                redeem_price_per_dfa: payload.redeem_price_per_dfa,
                repayment_fee: payload.repayment_fee,
                redemption_spread: payload.redemption_spread,
                transaction: data,
            }
            yield* signCustomTransaction(payload.skid, dataForSign, api.lib.putDfaRepaymentPayload)
            yield* put(clearDataTransactionDetails())
            openNotification({
                type: 'info',
                message: 'Запущен процесс погашения',
            })
            yield* put(getDfaById({ dfaId: payload.asset_id }))
        }
    } catch (error) {
        yield* put(clearDataTransactionDetails())
        const description =
            isAxiosError(error) && errorTranslates[error.response.data.error.code]
                ? `: ${errorTranslates[error.response.data.error.code]}`
                : ''
        openNotification({
            type: 'error',
            message: `Ошибка процесса погашения${description}`,
        })
    } finally {
        yield* put(dfaDetailsSlice.actions.setIsLoading(false))
    }
}

function* handleStoreDfaLink({ payload }: ReturnType<typeof storeLink>) {
    try {
        const dfa = yield* select(dfaDetailsSelector.selectDfaDetails)
        yield* put(updateLinks({ errorLink: '', isLoadingLink: true }))

        if (!dfa.id) {
            throw new Error('lost id')
        }

        const { error } = yield api.lib.patchDfaLink(`${dfa.id}`, { ...payload })
        if (error) {
            throw error
        }

        yield* put(updateLinks({ Links: payload, errorLink: '', isLoadingLink: false }))
    } catch (error) {
        yield* put(updateLinks({ errorLink: '', isLoadingLink: false, Links: {} }))
    }
}

function* handleEmissionDevDfaPrefly({ payload }: ReturnType<typeof emissionDevDfa>) {
    try {
        yield* put(setIsLoadingTransactionDetails(true))
        const { error, data } = yield api.lib.patchDfaDevEmission(payload)
        if (error || data?.error) {
            throw error || data?.error
        }

        yield* put(setItemsTransactionDetails(data))

        yield* put(setIsLoadingTransactionDetails(false))
    } catch (error) {
        yield* put(setIsLoadingTransactionDetails(false))
        openMessage({
            type: 'error',
            message: 'Ошибка формирования транзакции',
        })
    }
}

function* handleEmissionDevDfa({ payload }: ReturnType<typeof emissionDevDfa>) {
    try {
        yield* put(dfaDetailsSlice.actions.setIsLoading(true))

        const data = yield* select(transactionDetailsSelectors.selectItems)

        if (data?.item) {
            yield* signDataEmission(
                data?.item?.status,
                payload.skid,
                data,
                payload.asset_id,
                api.lib.putDfaDevEmission,
            )
            openNotification({
                type: 'success',
                message: 'Запрос на выпуск успешно отправлен',
            })
        }
        yield* put(loadsetAccountMoneyByUser())
        yield* put(clearDataTransactionDetails())
    } catch (error) {
        yield* put(clearDataTransactionDetails())
        openNotification({
            type: 'error',
            message: 'Возникли ошибки при отправке запроса на выпуск',
        })
    } finally {
        yield* put(dfaDetailsSlice.actions.setIsLoading(false))
    }
}

function* handleGetDfaBalanceById({ payload }: ReturnType<typeof getDfaBalanceById>) {
    try {
        const uuid = yield* select(authSelector.selectUserUid)
        const isInvestorRepaymentCheck = yield* select(
            dfaDetailsSelector.selectIsInvestorRepaymentCheck,
        )

        const helperPayload: IGetDfaBalanceByIdHelperProps = {
            dfaId: payload.dfaId,
            withPayoffAgreements: payload.withPayoffAgreements,
            uuid,
            isInvestorRepaymentCheck,
        }

        const balance = yield* call(getDfaBalanceByIdHelper, helperPayload)

        if (balance) {
            yield* put(updateDfaBalance(balance))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось получить данные по балансу ЦФА',
        })
    }
}

function* downloadDecisionTemplateSaga({ payload }: ReturnType<typeof downloadDecisionTemplate>) {
    try {
        const profileTypes = yield* select(profileSelector.selectProfileTypes)
        const userUuid = yield* select(authSelector.selectUserUid)
        let user_id = ''
        if (profileTypes?.includes(ProfileType.LEGL) && userUuid) {
            user_id = userUuid
        }
        const { data, error } = yield* call(api.lib.getDfaDecisionFileService, {
            ...payload,
            user_id,
        })
        if (error || !data?.item || !data.item?.name || !data.item?.docx) {
            throw new Error(error ?? '')
        } else {
            const { docx, name } = data.item

            const a = document.createElement('a')
            a.href = `${docx}`
            a.download = `${name}`
            a.click()
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Ошибка загрузки документа',
        })
    }
}

function* handleRepaymentDfaConfirmByInvestorPrefly({
    payload,
}: ReturnType<typeof repaymentDfaConfirmByInvestor>) {
    try {
        yield* put(setIsLoadingTransactionDetails(true))

        const { error, data } = yield* call(api.lib.repaymentDfaConfirmByInvestorServiceV2, payload)
        if (error || data?.error) {
            throw error || data?.error
        }
        yield* put(setItemsTransactionDetails(data))

        yield* put(setIsLoadingTransactionDetails(false))
    } catch (error) {
        yield* put(setIsLoadingTransactionDetails(false))
        openMessage({
            type: 'error',
            message: 'Ошибка формирования транзакции',
        })
    }
}

function* handleRepaymentDfaConfirmByInvestor({
    payload,
}: ReturnType<typeof repaymentDfaConfirmByInvestor>) {
    try {
        const data = yield* select(transactionDetailsSelectors.selectItems)

        if (data?.item) {
            yield* signData(data?.item?.status, payload.skid, data)
            openNotification({
                type: 'success',
                message: 'Подтверждение успешно отправлено',
            })
        }
        yield* put(clearDataTransactionDetails())
    } catch (error) {
        yield* put(clearDataTransactionDetails())
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при отправке подтверждения',
        })
    }
}

function* handleMakeIssueFailed({ payload }: ReturnType<typeof makeIssueFailed>) {
    try {
        const { error } = yield* call(api.lib.makeDFAIssueFailed, payload)

        if (error) {
            throw new Error(error)
        }

        openMessage({
            type: 'success',
            message: 'Эмитент признал выпуск ЦФА несостоявшимся',
        })
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось признать выпуск несостоявшимся',
        })
    }
}

function* handleRepaymentDfaFee({ payload }: ReturnType<typeof getRepaymentDfaFee>) {
    try {
        const data = yield* call(getCalculateFeeHelper, payload)

        yield* put(dfaDetailsSlice.actions.setDfaDetailsFee(data.item.fee_amount))
    } catch (error) {
        yield* put(dfaDetailsSlice.actions.setDfaDetailsFee(undefined))
    }
}

function* handleRevokeEmitment({ payload }: ReturnType<typeof revokeEmitment>) {
    try {
        const { error, data } = yield* call(api.lib.emitmentRevokeService, payload)
        if (error || data?.error) {
            throw error ?? data?.error
        }

        if (payload) {
            yield* put(getDfaById({ dfaId: payload }))
        }
    } catch (error) {
        openMessage({ type: 'error', message: 'Не удалось отменить выпуск' })
        yield* put(updateDfaDetails({ error: 'dfa error', isLoading: false }))
    }
}

function* handleSendToTellerDfa({ payload }: ReturnType<typeof sendToTellerDfa>) {
    try {
        const { error } = yield* call(api.lib.confirmToTellerDfaService, payload)
        if (error) {
            throw error
        }

        if (payload) {
            yield* put(getDfaById({ dfaId: payload }))
        }
    } catch (error) {
        openMessage({ type: 'error', message: 'Не удалось подать заявление' })
        yield* put(updateDfaDetails({ error: 'dfa error', isLoading: false }))
    }
}

function* handlePatchRedeemDate({ payload }: ReturnType<typeof patchRedeemDate>) {
    try {
        const dfa = yield* select(dfaDetailsSelector.selectDfaDetails)
        if (!dfa.id) {
            return
        }
        const { error } = yield* call(api.lib.patchRedeemDateByDfaId, dfa.id, payload.date)
        if (error) {
            throw error
        }
        yield* put(
            updateDfaDetails({
                error: '',
                isLoading: false,
                redeem_date: payload.date * 1000,
            }),
        )
        payload.callback()
    } catch (error) {
        openNotification({
            type: 'error',
            message: `Не удалось сохранить дату погашения${
                isAxiosError(error) ? `:\n ${errorTranslates[error.response.data.error.code]}` : ''
            }`,
        })
        yield* put(
            updateDfaDetails({
                error: 'dfa error',
                isLoading: false,
                redeem_date: payload.date * 1000,
            }),
        )
    }
}

function* handleSetCouponPayment({ payload }: ReturnType<typeof setCouponPayment>) {
    try {
        const { error } = yield* call(api.lib.sendDfaCouponPayment, payload)
        const dfa = yield* select(dfaDetailsSelector.selectDfaDetails)
        if (error) {
            throw error
        }

        openNotification({
            type: 'success',
            message: `Купонный доход по ЦФА ${dfa.ticker_symbol} успешно выплачен.`,
            description: `Дата выплаты: ${dayjs().format(VIEW_DATE_FORMAT)}`,
        })

        if (dfa.id) {
            yield* put(getDfaById({ dfaId: dfa.id }))
        }
        yield* put(loadsetAccountMoneyByUser())
    } catch (error) {
        const description =
            isAxiosError(error) &&
            error.response.data.error.code === 'ERR_MONEY_BALANCE_IS_NOT_ENOUGH'
                ? 'На вашем Лицевом счете недостаточно денежных средств'
                : ''
        openNotification({
            type: 'error',
            message: `Купонный доход не может быть выплачен`,
            description,
        })
        yield* put(
            updateDfaDetails({
                error: 'dfa error',
                isLoading: false,
                redeem_date: payload.date * 1000,
            }),
        )
    }
}

export function* watchDfaSagas(): Generator<StrictEffect> {
    yield* takeLatest(postDfa.type, handlePostDfa)
    yield* takeLatest(putDfa.type, handlePutDfa)
    yield* takeLatest(getDfaById.type, handleDfaById)
    yield* takeLatest(storeLink.type, handleStoreDfaLink)
    yield* takeLatest(emissionDevDfaPrefly.type, handleEmissionDevDfaPrefly)
    yield* takeLatest(emissionDevDfa.type, handleEmissionDevDfa)
    yield* takeLatest(startRepayment.type, handleStartRepayment)
    yield* takeLatest(startRepaymentPrefly.type, handleStartRepaymentPrefly)
    yield* takeLatest(getDfaBalanceById.type, handleGetDfaBalanceById)
    yield* takeLatest(downloadDecisionTemplate.type, downloadDecisionTemplateSaga)
    yield* takeLatest(
        repaymentDfaConfirmByInvestorPrefly.type,
        handleRepaymentDfaConfirmByInvestorPrefly,
    )
    yield* takeLatest(repaymentDfaConfirmByInvestor.type, handleRepaymentDfaConfirmByInvestor)
    yield* takeLatest(makeIssueFailed.type, handleMakeIssueFailed)
    yield* takeLatest(getRepaymentDfaFee.type, handleRepaymentDfaFee)
    yield* takeLatest(revokeEmitment.type, handleRevokeEmitment)
    yield* takeLatest(sendToTellerDfa.type, handleSendToTellerDfa)
    yield* takeLatest(patchRedeemDate.type, handlePatchRedeemDate)
    yield* takeLatest(setCouponPayment.type, handleSetCouponPayment)
}
