import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { createSelector } from 'reselect'

import { DfaStatusEnum, RedemptionTypeDfa, SettlementsType } from '@dltru/dfa-models'
import { DefaultOptionType } from '@dltru/dfa-ui'

import { AppState } from '@store/index'
import IAppState from '@store/states'

import { normalyzeCouponePayment } from './helpers'

dayjs.extend(isBetween)

const selectDfaDetailsStatus = (state: IAppState) => state.dfaDetails.status
const selectDfaDetails = (state: IAppState) => state.dfaDetails

const selectIsCouponPaymentCalendar = (state: IAppState) => state.dfaDetails.coupon_payment_calendar

const selectIsShowApplication = createSelector(selectDfaDetailsStatus, (status) => {
    return ['collection_started'].includes(status)
})

const selectIsShowEmitterAccountDetails = createSelector(selectDfaDetails, (dfa) => {
    return (
        dfa.status === 'collection_started' && dfa.issue_settlements_type === SettlementsType.direct
    )
})

const selectIsShowDeals = createSelector(selectDfaDetailsStatus, (status) => {
    return [
        DfaStatusEnum.redeemed,
        DfaStatusEnum.emitment_success,
        DfaStatusEnum.mature,
        DfaStatusEnum.mature_confirmed,
    ].includes(status)
})
const selectIsShowTransfer = createSelector(selectDfaDetailsStatus, (status) => {
    return ['redeem_started', 'emitment_success'].includes(status)
})

const selectIsShowOwners = createSelector(selectDfaDetails, (dfa) => {
    if (
        dfa.status === DfaStatusEnum.mature &&
        dfa.repayment_settlements_type === SettlementsType.direct
    ) {
        return true
    }
    return [
        DfaStatusEnum.emitment_success,
        DfaStatusEnum.mature_confirmed,
        DfaStatusEnum.mature_preparation,
        DfaStatusEnum.redeemed,
    ].includes(dfa.status)
})

const selectIsShowCoupon = createSelector(selectDfaDetails, (dfa) => {
    return (
        dfa.is_coupon_payment &&
        [
            DfaStatusEnum.redeemed,
            DfaStatusEnum.emitment_success,
            DfaStatusEnum.mature,
            DfaStatusEnum.mature_confirmed,
        ].includes(dfa.status)
    )
})

const selectEarlyRepayment = createSelector(selectDfaDetails, (dfa) => {
    return (
        dfa.is_early_repayment &&
        [
            DfaStatusEnum.redeemed,
            DfaStatusEnum.emitment_success,
            DfaStatusEnum.mature,
            DfaStatusEnum.mature_confirmed,
        ].includes(dfa.status)
    )
})

const selectIsForPlatformRepayment = createSelector(
    (state: IAppState) => state.dfaDetails.emitter_id,
    (state: IAppState) => state.dfaDetails.repayment_settlements_type,
    (state: IAppState) => state.dfaDetails.redeem_date,
    (state: IAppState) => state.auth.uuid,
    selectDfaDetailsStatus,
    (emitter_id, settlements_type, redeem_date, uuid, status) => {
        if (emitter_id !== uuid || settlements_type !== SettlementsType.platform || !redeem_date) {
            return false
        }

        const _redeem_date = dayjs(redeem_date)

        return (
            _redeem_date.isValid() &&
            _redeem_date.diff(dayjs().startOf('day'), 'days') <= 5 &&
            status === DfaStatusEnum.emitment_success
        )
    },
)

const selectIsForDirectRepayment = createSelector(
    (state: IAppState) => state.dfaDetails.emitter_id,
    (state: IAppState) => state.dfaDetails.repayment_settlements_type,
    (state: IAppState) => state.dfaDetails.redeem_date,
    (state: IAppState) => state.auth.uuid,
    selectDfaDetailsStatus,
    (emitter_id, settlements_type, redeem_date, uuid, status) => {
        if (emitter_id !== uuid || settlements_type !== SettlementsType.direct) {
            return false
        }

        const _redeem_date = dayjs(redeem_date)
        const _isBetween =
            dayjs().isBetween(_redeem_date.subtract(5, 'day'), _redeem_date) ||
            dayjs().diff(_redeem_date) > 0

        return (
            _redeem_date.isValid() &&
            _isBetween &&
            [
                DfaStatusEnum.emitment_success,
                DfaStatusEnum.mature_confirmed,
                DfaStatusEnum.mature,
            ].includes(status)
        )
    },
)

const selectIsInvestorRepaymentCheck = createSelector(
    (state: IAppState) => state.dfaDetails.status,
    (state: IAppState) => state.dfaDetails.repayment_settlements_type,
    (state: IAppState) => state.dfaDetails.emitter_id,
    (state: IAppState) => state.auth.uuid,
    (status, repayment_settlements_type, emitter_id, uuid) =>
        status === DfaStatusEnum.mature &&
        repayment_settlements_type === SettlementsType.direct &&
        emitter_id !== uuid,
)

const selectFullBalance = createSelector(
    (state: IAppState) => state.dfaDetails.balance,
    (balance) =>
        balance
            ? balance.active_amount_dfa +
              balance.blocked_amount_dfa +
              balance.encumbrance_amount_dfa +
              balance.injunction_amount_dfa
            : 0,
)

const selectCouponPayments = createSelector(
    selectIsCouponPaymentCalendar,
    (state: IAppState) => state.dfaDetails.couponeTableCurrentPage || 1,
    (calendar, currentPage) => {
        return normalyzeCouponePayment(calendar, currentPage)
    },
)

const selectIsCouponPaymentsEmpty = createSelector(selectCouponPayments, (calendar) => {
    return calendar?.data.every((value) => {
        return !value.cpf_id
    })
})

const selectCouponPaymentsDatesOptions = createSelector(
    selectIsCouponPaymentCalendar,
    (calendar): DefaultOptionType[] =>
        calendar?.map((value) => ({ value: value.id, label: value.payment_date })) || [],
)

const selectCouponPaymentsDatesOptionById = createSelector(
    selectCouponPaymentsDatesOptions,
    (_: AppState, optionId: number) => optionId,
    (options, id) => options.find((option) => option.value === id),
)

const selectRedeemPricePerDfa = createSelector(
  selectDfaDetails,
  (dfa) => dfa.redemption_type === RedemptionTypeDfa.fixed 
    ? dfa.redeem_price_per_dfa 
    : dfa.current_redeem_price_per_dfa,
)

export const dfaDetailsSelector = {
    selectDfaDetailsStatus,
    selectIsShowApplication,
    selectIsShowDeals,
    selectIsShowTransfer,
    selectIsShowOwners,
    selectIsForPlatformRepayment,
    selectDfaDetails,
    selectIsForDirectRepayment,
    selectIsInvestorRepaymentCheck,
    selectFullBalance,
    selectCouponPayments,
    selectIsCouponPaymentsEmpty,
    selectIsCouponPaymentCalendar,
    selectCouponPaymentsDatesOptions,
    selectCouponPaymentsDatesOptionById,
    selectIsShowCoupon,
    selectEarlyRepayment,
    selectIsShowEmitterAccountDetails,
    selectRedeemPricePerDfa
}
