
import { FilterRes } from '@dltru/dfa-models'

import IAppState from '../../states'

const currentPage = (state: IAppState): FilterRes => {
    if (state.dfaDealsList.isLoading) {
        return { Rows: [], Total: 0, loading: true }
    }

    const { data, range } = state.dfaDealsList

    // TODO: фильтрация

    // TODO: сортировка

    const offset = range.offset || 0
    const limit = range.limit || 10

    return {
        Rows: data.slice(offset, offset + limit),
        Total: data.length,
        loading: false,
    }
}
const selectData = (state: IAppState) => state.dfaDealsList.data

export const dealsSelector = {
    currentPage,
    selectData,
}
