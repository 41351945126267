import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

import {
    ConfirmRepaymentPayload,
    ICalcFeeRequestProps,
    ICouponPaymentPayload,
    IDfaFront,
    IDfaOwnerBalance,
    IEmissionDfaPayload,
    IMakeIssueFailed,
    PostDfaRepaymentPayload,
    PutDfaRepaymentPayload,
    UploadDocx,
} from '@dltru/dfa-models'

import { initialState } from '../resources'
import { IDfaLink } from '../types'

export const dfaDetailsSlice = createSlice({
    name: 'dfaDetails',
    initialState,
    reducers: {
        updateDfaDetails(state, action) {
            const data = { ...action.payload }
            return { ...state, ...data }
        },
        updateDfaBalance(state, action: PayloadAction<IDfaOwnerBalance>) {
            state.balance = action.payload
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        updateLinks(state, action) {
            if (action.payload.Links) {
                state.ois_link = action.payload.Links.ois_link
                state.emitter_link = action.payload.Links.emitter_link
            }
            state.isLoadingLink = action.payload.isLoadingLink
            state.errorLink = action.payload.errorLink
        },
        dropDfaDetails() {
            return { ...initialState }
        },
        setDfaError(state, action) {
            state.error = action.payload
        },
        setDfaDetailsFee(state, action) {
            state.fee = action.payload
        },
        setCouponeCurrentPage: (state, action) => {
            state.couponeTableCurrentPage = action.payload
        },
        setDfasDecisionIssueFeeAmount: (state, action) => {
            state.issue_fee_amount = action.payload
        },
    },
})

export const storeLink = createAction<IDfaLink>('dfaDetails/storeDfa')
export const postDfa = createAction<IDfaFront | undefined>('dfaDetails/postDfa')
export const setDfasDecisionIssueFeeAmount = createAction<number>(
    'dfaDetails/setDfasDecisionIssueFeeAmount',
)
export const putDfa = createAction<IDfaFront>('dfaDetails/putDfa')
export const getDfaById =
    createAction<{ dfaId: number; withBalance?: boolean }>('dfaDetails/getDfaById')
export const getDfaBalanceById = createAction<{ dfaId: number; withPayoffAgreements?: boolean }>(
    'dfaDetails/getDfaBalanceById',
)
export const startRepayment = createAction<
    PostDfaRepaymentPayload & Omit<PutDfaRepaymentPayload, 'transaction'>
>('dfaDetails/startRepayment')

export const startRepaymentPrefly = createAction<
    PostDfaRepaymentPayload & Omit<PutDfaRepaymentPayload, 'transaction'>
>('dfaDetails/startRepaymentPrefly')

export const repaymentDfaConfirmByInvestorPrefly = createAction<ConfirmRepaymentPayload>(
    'dfaDetails/repaymentDfaConfirmByInvestorPrefly',
)

export const repaymentDfaConfirmByInvestor = createAction<ConfirmRepaymentPayload>(
    'dfaDetails/repaymentDfaConfirmByInvestor',
)
export const { updateLinks, updateDfaDetails, dropDfaDetails, updateDfaBalance } =
    dfaDetailsSlice.actions

export const emissionDevDfaPrefly = createAction<IEmissionDfaPayload>(
    'dfaDetails/emissionDevDfaPrefly',
)

export const emissionDevDfa = createAction<IEmissionDfaPayload>('dfaDetails/emissionDevDfa')

export const downloadDecisionTemplate = createAction<Omit<UploadDocx, 'user_id'>>(
    'dfaDetails/downloadDecisionTemplate',
)

export const makeIssueFailed = createAction<IMakeIssueFailed>('dfaDetails/makeIssueFailed')
export const getRepaymentDfaFee = createAction<ICalcFeeRequestProps>(
    'dfaDetails/getRepaymentDfaFee',
)
export const revokeEmitment = createAction<number>('dfaDetails/revokeEmitment')
export const sendToTellerDfa = createAction<number>('dfaDetails/sendToTellerDfa')
export const patchRedeemDate = createAction<{ date: number; callback: () => void }>(
    'dfaDetails/patchRedeemDate',
)

export const setCouponPayment = createAction<ICouponPaymentPayload>('couponPayment/setPayment')

export default dfaDetailsSlice.reducer
